const config = {
    dev: {
        api: {
            useHTTPS: true,
            host: 'localhost',
            port: 443
        }
    },
    prod: {
        api: {
            useHTTPS: true,
            host: 'bot.terraprof.ua',
            port: 443
        }
    }
};

export default process.env.NODE_ENV === 'production' ? config.prod : config.dev;
